import data from "../scripts/data.json";
export const getUserContributions = (usernames, week) => {
    const userResponses = data.filter(({ data: { user: { login }, }, }) => usernames.includes(login));
    const calendarMap = {};
    for (const { data: { user: { contributionsCollection: { contributionCalendar: { weeks }, }, }, }, } of userResponses) {
        const filteredWeeks = week
            ? weeks.filter((_, i) => weeks.length - i === week)
            : weeks;
        for (const { contributionDays } of filteredWeeks) {
            for (const { date: dateIso, contributionCount } of contributionDays) {
                const date = new Date(dateIso);
                const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                if (calendarMap[dateString]) {
                    calendarMap[dateString] += contributionCount;
                }
                else {
                    calendarMap[dateString] = contributionCount;
                }
            }
        }
    }
    const calendarData = Object.entries(calendarMap).map(([dateString, value]) => ({
        date: new Date(dateString),
        value,
    }));
    return calendarData;
};
export const getContributionsForWeek = (calendarData, weekStart) => {
    const weekData = calendarData.filter(({ date }) => date.getDay() <= weekStart.getDay() && weekStart.getDay() >= date.getDay());
    return weekData;
};
