<span
  class="ml-2 border-yellow-200 shadow-md shadow-yellow-300/100 flex rounded-sm bg-yellow-100 pl-2"
>
  <slot />
  <img
    src="/assets/coin.gif"
    alt="a pixel art coin spinning"
    class="h-[1em] mr-0.5 mt-0.5"
  />
  <span class="font-bold pr-2">MitchBux</span>
</span>
